import axiosInstance from '../config/axiosConfig';

export const sendMailChimpEmail = async (userEmail) => {
    try {
        const data = {
            email: userEmail,
        };

        const respAxios = await axiosInstance.post(
            `${process.env.REACT_APP_CORE_API_URI}/web/integrations/mailchimp/subscribe`,
            data
        );

        return respAxios.status;
    } catch (err) {
        console.error('error: ', err.response);
        return err.response.status;
    }
};
