import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserToken, fetchUserId } from '../../../redux/slices/users';
import { LogInWithEmail } from '../../../api/UserController';
import { SUPERADMIN_ROLE, ADMIN_ROLE, GUEST_ROLE, USER_ROLE } from '../../../constants';
import { SaveMerchant } from '../../../redux/actions/MerchantActions';
import { SaveUser } from '../../../redux/actions/UserActions';
import Button from '../../../components/common/button/Button';
import { isValidEmail } from '../../../utils/generalUtils';
import { CheckBoxMain } from '../../../components/common/checkBox/CheckBoxMain';
import { setColor, setFont } from '../../../assets/styles/styles';
import { Input } from '../../../components/common/input/Input';

const LoginForm = ({ setMessage }) => {
    const dispatch = useDispatch();

    const [emailValue, setEmailValue] = useState('');
    const [emailErrorMsg, setEmailErrorMsg] = useState('');
    const [isEmailError, setIsEmailError] = useState(false);

    const [passwordValue, setPasswordValue] = useState('');
    const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
    const [isPasswordError, setIsPasswordError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [shouldDisableAutoComplete, setShouldDisableAutoComplete] = useState(true);

    const password = useRef();

    useEffect(() => {
        const hasVisited = localStorage.getItem('hasVisitedLoginPage');
        if (hasVisited) {
            setShouldDisableAutoComplete(false);
        } else {
            localStorage.setItem('hasVisitedLoginPage', 'true');
        }
    }, []);

    const handleEmailChange = (e) => {
        const { value } = e.target;
        setEmailValue(value);

        if (isEmailError && value !== '') {
            setIsEmailError(false);
            setEmailErrorMsg('');
        }
    };

    const handlePasswordChange = (e) => {
        const { value } = e.target;
        setPasswordValue(value);

        if (isPasswordError && value !== '') {
            setIsPasswordError(false);
            setPasswordErrorMsg('');
        }
    };

    const handleFormValidtion = async () => {
        if (emailValue === '') {
            setIsEmailError(true);
            setEmailErrorMsg('Email is required');
            return false;
        }

        if (!isValidEmail(emailValue)) {
            setIsEmailError(true);
            setEmailErrorMsg('Email must be a valid email address');
            return false;
        }

        if (passwordValue === '') {
            setIsPasswordError(true);
            setPasswordErrorMsg('Password is required');
            return false;
        }

        return true;
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        setMessage('');
        setIsEmailError(false);
        setEmailErrorMsg('');
        setIsPasswordError(false);
        setPasswordErrorMsg('');

        if (!(await handleFormValidtion())) {
            console.log('returning due to incorrect form');
            setIsLoading(false);
            return;
        }

        try {
            const res = await LogInWithEmail(emailValue, passwordValue);

            if (res.status === 200) {
                const role = res.userRoles[0];
                if (role === SUPERADMIN_ROLE || role === ADMIN_ROLE || res.isMerchant) {
                    setMessage('Login Successful. Admin');
                    if (res.isMerchant) {
                        const {
                            merchantName,
                            merchantId,
                            street,
                            city,
                            country,
                            postalCode,
                            merchantBusinessType,
                            stripeClientId,
                        } = res.merchant;
                        const address = { street, city, country, postalCode };

                        dispatch(SaveMerchant(merchantName, merchantId, address, merchantBusinessType, stripeClientId));
                    }

                    if (res.userRoles[0] === SUPERADMIN_ROLE || res.userRoles[0] === ADMIN_ROLE || res.isMerchant) {
                        dispatch(
                            SaveUser(
                                res.user.email,
                                res.user.displayName,
                                res.token,
                                res.userId,
                                res.userRoles[0],
                                res.isMerchant,
                                res.expiration,
                                res.profileIconBase64
                            )
                        );
                    }
                } else if (res.userRoles[0] === GUEST_ROLE || res.userRoles[0] === USER_ROLE) {
                    dispatch(
                        SaveUser(
                            res.user.email,
                            res.user.displayName,
                            res.token,
                            res.userId,
                            res.userRoles[0],
                            res.isMerchant,
                            res.expiration,
                            res.profileIconBase64
                        )
                    );
                } else {
                    dispatch(
                        SaveUser(
                            res.user.email,
                            res.user.displayName,
                            res.token,
                            res.userId,
                            '',
                            res.isMerchant,
                            res.expiration,
                            res.profileIconBase64
                        )
                    );
                }
            } else {
                setMessage('Failed to login, please ensure your information is correct');
            }
        } catch (error) {
            console.error('API call error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <StyledForm
            id="formLogin"
            onSubmit={(e) => onSubmit(e)}
            autoComplete={shouldDisableAutoComplete ? 'off' : 'on'}
        >
            <input
                type="email"
                style={{ display: 'none' }}
                autoComplete={shouldDisableAutoComplete ? 'off' : 'email'}
            />
            <input
                type="password"
                style={{ display: 'none' }}
                autoComplete={shouldDisableAutoComplete ? 'off' : 'new-password'}
            />
            <Input
                variant="LoginInput"
                type="email"
                placeholder="Email address"
                value={emailValue}
                onChangeHandler={handleEmailChange}
            />
            {isEmailError && !isValidEmail(emailValue) && <ErrorMessage>{emailErrorMsg}</ErrorMessage>}

            <Input
                variant="LoginInput"
                type="password"
                placeholder="Password"
                ref={password}
                value={passwordValue}
                onChangeHandler={handlePasswordChange}
            />
            {isPasswordError && <ErrorMessage>{passwordErrorMsg}</ErrorMessage>}

            <StyledCheckboxLabel>
                <CheckBoxMain
                    height={'25px'}
                    width={'25px'}
                    fontSize={16}
                    labelTextRight={'Stay signed in'}
                    onChange={() => {}}
                />
            </StyledCheckboxLabel>
            <Button
                label="SIGN IN"
                buttonWidth={'100%'}
                variant={!isValidEmail(emailValue) || passwordValue === '' ? 'DefaultButton' : 'PinkButton'}
                type="submit"
                disabled={isLoading}
            >
                SIGN IN
            </Button>

            <StyledLink to="/reset-password">Forgot password?</StyledLink>
        </StyledForm>
    );
};

LoginForm.propTypes = {
    setMessage: PropTypes.func.isRequired,
};

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    padding: 20px;
    box-sizing: border-box;

    @media (max-width: 768px) {
        width: 90%;
    }

    @media (max-width: 480px) {
        width: 100%;
    }
`;

const ErrorMessage = styled.div`
    line-height: 1.5;
    font-size: 0.75rem;
    font-family: ${setFont.barlow};
    font-weight: 400;
    text-align: left;
    color: ${setColor.errorColor};
    margin-left: 10px;
`;

const StyledCheckboxLabel = styled.div`
    width: fit-content;
    display: flex;
    padding: 5px 10px 30px 1px;
    margin-top: 10px;
`;

const StyledLink = styled(RouterLink)`
    color: ${setColor.accent4};
    text-decoration: none;
    text-align: center;
    font-size: 18px;
    color: ${setColor.purpleNavigation};
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin: 20px;
    :hover {
        color: ${setColor.accent4};
    }
`;

export default LoginForm;
