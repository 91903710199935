/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SaveUser } from '../../redux/actions/UserActions';
import IntraverseLogoFull from '../landingPages/intraverseLandingPage/IntraverseHomeImages/IntraverseLogoFull.png';
import Footer from '../../components/layout/footer/Footer';
// API & Utils
import { RegisterUser, emailExistsChecker, usernameExistsChecker } from '../../api/UserController';
// import AuthSocial from '../sections/auth/AuthSocial';
import { validateUsername, nameValidation, isvalidPassword, isValidEmail } from '../../utils/generalUtils';
import BackgroundContainer from '../../components/common/Backgrounds/BackgroundContainer';
import { CheckBoxMain } from '../../components/common/checkBox/CheckBoxMain';
import { setColor, setFont } from '../../assets/styles/styles';
import PasswordChecker from './PasswordChecker';
import { Input } from '../../components/common/input/Input';
import Button from '../../components/common/button/Button';
import { countryOptions } from '../../constants';
import LoadingIcon from '../../components/common/loading/LoadingIcon';

export default function RegisterUserPage() {
    const dispatch = useDispatch();

    // Form data states
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [country, setCountry] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');

    // Page Section state handling
    const [section, setSection] = useState('name');
    const [isLoading, setIsLoading] = useState(false);

    // Error states
    const [error, setError] = useState('');
    const [nameError, setNameError] = useState('');
    const [nameValidError, setNameValidError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [termsError, setTermsError] = useState('');
    const [isNewsChecked, setIsNewsChecked] = useState(false);
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [emailExistsBlock, setEmailExistsBlock] = useState(false);
    const [usernameExists, setUsernameExists] = useState(false);

    useEffect(() => {
        if (
            !nameValidation(firstName.trim(), lastName.trim()) &&
            firstName.trim().length > 0 &&
            lastName.trim().length > 0
        ) {
            setNameValidError('Your name should only contain letters and/or spaces');
        } else {
            setNameValidError('');
        }
        if (firstName.trim() !== '' && lastName.trim() !== '' && country !== '') {
            setNameError('');
        } else setNameError('*Please fill out all fields');
    }, [firstName, country, lastName]);

    const handleEmailSubmit = async () => {
        try {
            const exists = await emailExistsChecker(email.toLowerCase());
            if (exists) {
                setError('This email is already in use, please sign in, or try another email.');
                setEmailExistsBlock(true);
            } else if (isValidEmail(email) && isvalidPassword(password) && !exists) {
                setError('');
                setTermsError('');
                setEmailExistsBlock(false);
                setSection('username');
            } else if (!isValidEmail(email)) {
                setError('Please enter a valid email address.');
            } else if (!isvalidPassword(password)) {
                setError('Please select a valid password before continuing.');
            }
        } catch {
            setError('An error occurred, please reload the page and try again.');
        }
    };
    const handleEmailChange = async (e) => {
        setEmail(e.target.value);
        const exists = await emailExistsChecker(e.target.value.toLowerCase());
        if (exists) {
            setError('This email is already in use, please sign in, or try another email.');
            setEmailExistsBlock(true);
        } else {
            setError('');
            setEmailExistsBlock(false);
        }
    };

    const handleTermsSubmit = async (event) => {
        event.preventDefault();
        setTermsError('');
        setIsLoading(true);

        if (!isTermsChecked) {
            setTermsError('Please accept our terms & conditions before signing up');
            setIsLoading(false);
            return;
        }
        await RegisterUser(firstName.trim(), lastName.trim(), email, password, username, isNewsChecked).then((res) => {
            if (res !== null) {
                console.log(res);
                setIsLoading(false);
                dispatch(
                    SaveUser(
                        res.user.email,
                        res.user.displayName,
                        res.token,
                        res.userId,
                        '',
                        res.isMerchant,
                        res.expiration,
                        true
                    )
                );
            } else {
                setTermsError('Error: There was an issue with registration, please go back to try again.');
                setIsLoading(false);
            }
        });
    };

    const handleUsernameSubmit = () => {
        if (!validateUsername(username) && username.length > 2) {
            setUsernameError(
                'Username must start with a letter, can only contain letters, numbers, underscores, or hyphens, and be between 3 and 20 characters long.'
            );
        } else if (usernameExists) {
            setUsernameError('Username already exists, please try a unique username.');
        } else {
            setUsernameError('');
            setTermsError('');
            setSection('terms');
        }
    };

    const handleUsernameChange = (event) => {
        const newUsername = event.target.value;
        setUsername(newUsername);

        // Check if username is valid
        if (!validateUsername(newUsername) && newUsername.length > 2) {
            setUsernameError(
                'Username must start with a letter, can only contain letters, numbers, underscores, or hyphens, and be between 3 and 20 characters long.'
            );
        } else if (validateUsername(newUsername)) {
            usernameExistsChecker(newUsername).then((res) => {
                setUsernameExists(res);
                setUsernameError('Username already exists, please try a unique username.');
            });
        } else {
            setUsernameError('');
        }
    };

    const handleNewsCheckboxChange = () => {
        setIsNewsChecked(!isNewsChecked);
    };
    const handleTermsCheckboxChange = () => {
        setIsTermsChecked(!isTermsChecked);
    };

    return (
        <BackgroundContainer loginBorder>
            <HeaderDiv>
                <Link to="/">
                    <LoginLogo src={IntraverseLogoFull} alt="Company Logo" />
                </Link>
                <Link to="/login">
                    <CreateAccountButton>SIGN IN</CreateAccountButton>
                </Link>
            </HeaderDiv>
            <CustomContainer>
                {/* NAME AND COUNTRY PAGE */}

                {section === 'name' && (
                    <>
                        <StepText>STEP 1 OF 4</StepText>
                        <LoginTitle>WHAT'S YOUR NAME?</LoginTitle>
                        <LoginSubtitle>
                            We will keep this information private and use it to verify your identity when contacting
                            Intraverse.
                        </LoginSubtitle>
                        {/* <AuthSocial /> */}
                        <InputContainer>
                            <CountrySelect value={country} onChange={(e) => setCountry(e.currentTarget.value)}>
                                <option value="" disabled hidden>
                                    Select a country
                                </option>
                                {countryOptions.map((country) => (
                                    <option key={country} value={country}>
                                        <p style={{ margin: '5px', width: '100px' }}> {country}</p>
                                    </option>
                                ))}
                            </CountrySelect>
                            <Input
                                variant="LoginInput"
                                type="text"
                                value={firstName}
                                onChangeHandler={(e) => setFirstName(e.target.value)}
                                placeholder="First Name"
                            />
                            <Input
                                variant="LoginInput"
                                type="text"
                                value={lastName}
                                onChangeHandler={(e) => setLastName(e.target.value)}
                                placeholder="Last Name"
                            />
                        </InputContainer>
                        {nameError && <p style={{ color: setColor.errorColor }}>{nameError}</p>}
                        {nameValidError && <p style={{ color: setColor.errorColor }}>{nameValidError}</p>}
                        <Button
                            variant={'PinkButton'}
                            buttonWidth={'100%'}
                            margin={'1.5rem 0 1rem 0'}
                            label={'Continue to Email'}
                            style={{
                                background:
                                    nameError === '' && nameValidError === '' && country !== ''
                                        ? setColor.pinkButtonBgGradient
                                        : setColor.loadingButtonBg,
                                color:
                                    nameError === '' && nameValidError === '' && country !== ''
                                        ? setColor.hightlight
                                        : setColor.iconColor,
                            }}
                            onClick={
                                nameError === '' && nameValidError === '' && country !== ''
                                    ? () => setSection('email')
                                    : null
                            }
                        />
                    </>
                )}

                {/* EMAIL AND PASSWORD PAGE */}

                {section === 'email' && (
                    <>
                        <StepText>STEP 2 OF 4</StepText>
                        <LoginTitle>WHAT'S YOUR EMAIL?</LoginTitle>
                        <LoginSubtitle>
                            The email you provide below will be used to log in Intraverse on desktop and mobile.
                        </LoginSubtitle>
                        <InputContainer autoComplete="off">
                            <input type="email" style={{ display: 'none' }} autoComplete={'email'} />
                            <input type="password" style={{ display: 'none' }} autoComplete={'new-password'} />
                            <Input
                                variant="LoginInput"
                                type="email"
                                value={email}
                                onChangeHandler={handleEmailChange}
                                // onChangeHandler={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                            />
                            <Input
                                variant="LoginInput"
                                type="password"
                                value={password}
                                onChangeHandler={(event) => {
                                    setPassword(event.target.value);
                                }}
                                placeholder="Password"
                            />
                            {error && <p style={{ color: setColor.errorColor, alignSelf: 'flex-start' }}>{error}</p>}

                            <PasswordChecker password={password} />
                        </InputContainer>
                        <Button
                            variant={'PinkButton'}
                            buttonWidth={'100%'}
                            margin={'1.5rem 0 0.7rem 0'}
                            label={'Continue to Username'}
                            style={{
                                background:
                                    isValidEmail(email) && isvalidPassword(password) && !emailExistsBlock
                                        ? setColor.pinkButtonBgGradient
                                        : setColor.loadingButtonBg,
                                color:
                                    isValidEmail(email) && isvalidPassword(password) && !emailExistsBlock
                                        ? setColor.hightlight
                                        : setColor.iconColor,
                            }}
                            onClick={() => {
                                handleEmailSubmit();
                            }}
                        />
                        <StepText
                            style={{
                                color: setColor.iconColor3,
                                cursor: 'pointer',
                                marginTop: '0.5rem',
                                marginBottom: '0.6rem',
                            }}
                            onClick={() => setSection('name')}
                        >
                            Back to step 1
                        </StepText>
                    </>
                )}

                {/* USERNAME PAGE */}

                {section === 'username' && (
                    <>
                        <StepText>STEP 3 OF 4</StepText>
                        <LoginTitle>Create your username</LoginTitle>
                        <LoginSubtitle>
                            Choose the game you want to use in the Intraverse. Don’t worry, you can change this later!
                        </LoginSubtitle>
                        <InputContainer>
                            <Input
                                type="text"
                                value={username}
                                variant="LoginInput"
                                onChangeHandler={handleUsernameChange}
                                placeholder="Username"
                                maxLength={20}
                            />
                        </InputContainer>
                        {(validateUsername(username) && !usernameExists) ||
                            (usernameError !== '' && <p style={{ color: setColor.errorColor }}>{usernameError}</p>)}
                        <Button
                            variant={'PinkButton'}
                            buttonWidth={'100%'}
                            margin={'1.5rem 0 0.7rem 0'}
                            label={'Continue to Last Step'}
                            style={{
                                background:
                                    validateUsername(username) && !usernameExists
                                        ? setColor.pinkButtonBgGradient
                                        : setColor.loadingButtonBg,
                                color:
                                    validateUsername(username) && !usernameExists
                                        ? setColor.hightlight
                                        : setColor.iconColor,
                            }}
                            onClick={() => {
                                handleUsernameSubmit();
                            }}
                        />
                        <StepText
                            style={{
                                color: setColor.accent4,
                                cursor: 'pointer',
                                marginTop: '0.7rem',
                                marginBottom: '0.7rem',
                            }}
                            onClick={() => setSection('email')}
                        >
                            Back to step 2
                        </StepText>
                    </>
                )}

                {/* TERMS & CONDITIONS PAGE */}

                {section === 'terms' && (
                    <SubmitForm onSubmit={handleTermsSubmit}>
                        <StepText style={{ marginTop: '-1rem' }}>STEP 4 OF 4</StepText>
                        <LoginTitle>REVIEW OUR TERMS</LoginTitle>
                        <LoginSubtitle>You’re almost done! Please review our terms and opt-ins below.</LoginSubtitle>
                        <CheckboxContainer>
                            <TermsText>
                                <CheckBoxMain
                                    width="30px"
                                    height="30px"
                                    checked={isNewsChecked}
                                    onChange={handleNewsCheckboxChange}
                                />
                                <p style={{ marginLeft: '15px' }} onClick={handleNewsCheckboxChange}>
                                    {' '}
                                    Receive news, special offers, event information, and feedback surveys by email from
                                    Intraverse.
                                </p>
                            </TermsText>
                            <TermsText>
                                <CheckBoxMain
                                    width="30px"
                                    height="30px"
                                    checked={isTermsChecked}
                                    onChange={handleTermsCheckboxChange}
                                />
                                <p style={{ marginLeft: '15px' }} onClick={handleTermsCheckboxChange}>
                                    I acknowledge that I have read and understood{' '}
                                    <a
                                        style={{ textDecoration: 'none', color: setColor.accent4, fontWeight: 700 }}
                                        href="https://terrazero.com/privacy-policy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Intraverse Online Privacy Policy
                                    </a>{' '}
                                    and
                                    <a
                                        style={{ textDecoration: 'none', color: setColor.accent4, fontWeight: 700 }}
                                        href="https://terrazero.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {' '}
                                        End User License Agreement
                                    </a>
                                    .
                                </p>
                            </TermsText>
                        </CheckboxContainer>
                        {termsError && <p style={{ color: setColor.errorColor }}>{termsError}</p>}
                        <ContinueButton
                            style={{
                                background: isTermsChecked ? setColor.pinkButtonBgGradient : setColor.loadingButtonBg,
                                color:
                                    isValidEmail(email) && isvalidPassword(password)
                                        ? setColor.hightlight
                                        : setColor.iconColor,
                            }}
                            type="submit"
                        >
                            {isLoading ? (
                                <div>
                                    <LoadingIcon />
                                </div>
                            ) : (
                                'CREATE MY ACCOUNT'
                            )}
                        </ContinueButton>
                        <StepText
                            style={{ color: setColor.accent4, cursor: 'pointer', margin: '0rem 0 1rem 0' }}
                            onClick={() => setSection('username')}
                        >
                            Back to step 3
                        </StepText>
                    </SubmitForm>
                )}
                <Footer />
            </CustomContainer>
        </BackgroundContainer>
    );
}

// ----------------------------------------------------------------------

const CustomContainer = styled.div`
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    max-width: 600px;

    @media (min-width: 768px) {
        margin-top: -4rem;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
const LoginLogo = styled.img`
    display: flex;
    align-self: flex-start;
    width: 250px;
    height: auto;
    margin-bottom: 30px;
    margin-top: 10px;
    margin-left: 10px;

    @media (max-width: 768px) {
        align-self: center;
        margin-top: 0px;
        margin-right: 0px;
        margin-left: 0px;
    }
`;
const HeaderDiv = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;
    margin-right: 10px;
    margin-left: 10px;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        margin-left: 0px;
        margin-top: 10px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const LoginTitle = styled.h3`
    color: ${setColor.accent4};
    text-align: center;
    font-family: ${setFont.barlowCondensed};
    font-size: 50px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding: 10px 0 10px 0;

    @media (max-width: 960px) {
        font-size: 28px;
        margin-top: 10px;
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        font-size: 40px;
    }
`;

const LoginSubtitle = styled.p`
    color: ${setColor.circularProgress};
    text-align: center;
    font-family: ${setFont.barlow};
    font-size: 20px;
    font-weight: 400;
    margin: 1rem 0;
    @media (max-width: 960px) {
        margin: 1.5rem -10px;
        font-size: 16px;
    }
`;
const CreateAccountButton = styled.button`
    border-radius: 5px;
    border: 2px solid ${setColor.iconColor3};
    display: inline-flex;
    padding: 15px 35px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: ${setColor.iconColor3};
    text-align: center;
    font-family: ${setFont.barlow};
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    margin-right: 1rem;
    margin-top: 10px;
    cursor: pointer;

    @media (max-width: 768px) {
        width: 100%;
        align-self: center;
        margin-right: 0rem;
    }

    @media (min-width: 768px) and (max-width: 1440px) {
        width: fit-content;
        margin-top: 10px;
    }
`;

const ContinueButton = styled.button`
    border-radius: 5px;
    width: 100%;
    border: none;
    display: inline-flex;
    padding: 15px 35px;
    justify-content: center;
    align-items: center;
    background-color: ${setColor.loadingButtonBg};
    color: ${setColor.iconColor};
    text-align: center;
    font-family: ${setFont.barlow};
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    cursor: pointer;

    @media (max-width: 768px) {
        width: 100%;
        align-self: center;
        margin-bottom: 1rem;
        font-size: 14px;
        padding: 15px 10px;
    }

    @media (min-width: 768px) and (max-width: 1440px) {
        width: 100%;
        margin-top: 10px;
    }
`;

const InputContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const StepText = styled.p`
    color: ${setColor.nextStepText};
    text-align: center;
    font-family: ${setFont.barlowCondensed};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-top: -20px;
`;
const CountrySelect = styled.select`
    flex: 1;
    background: ${setColor.inputBgColor};
    border: 2px solid ${setColor.loadingButtonBg};
    border-radius: 5px;
    outline: none;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0rem;
    font-size: 18px;
    font-style: italic;
    font-family: ${setFont.barlow};
    color: ${setColor.iconColor};
    width: 100%;
    height: 70px;
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    flex-direction: column;
`;

const TermsText = styled.p`
    color: ${setColor.circularProgress};
    text-align: left;
    font-family: ${setFont.barlow};
    font-size: 20px;
    font-weight: 400;
    margin: 0.7rem 0;
    display: flex;
    flex-direction: row;

    @media (max-width: 320px) {
        width: 110%;
        margin-left: -10px;
        font-size: 18px;
        margin-top: 0rem;
    }

    & > a {
        color: ${setColor.accent4};
        font-weight: 700;
        cursor: pointer;
    }
`;
const SubmitForm = styled.form``;
