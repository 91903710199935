import axios from 'axios';
import { store } from '../redux/Store';
import { DeleteUser, refreshAccessToken } from '../redux/actions/UserActions';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_CORE_API_URI,
});

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
    async (config) => {
        const state = store.getState();
        const { token } = state.user;

        // Check if Authorization header is already set in the config
        if (!config.headers.Authorization && token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        // Ensure the other headers are still set if not provided
        config.headers.Accept = config.headers.Accept || 'application/json';
        config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json';

        return config;
    },
    (error) => Promise.reject(error)
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            const stateUser = store.getState().user;

            if (stateUser?.userId) {
                originalRequest._retry = true;
                await store.dispatch(refreshAccessToken(stateUser.userId));

                const newState = store.getState();
                const newToken = newState.user.token;

                axiosInstance.defaults.headers.common.Authorization = `Bearer ${newToken}`;
                return axiosInstance(originalRequest);
            }
        }
        return Promise.reject(error);
    }
);

export const API_POST = async (action, body, token, method) => {
    try {
        let headers = {
            'Content-Type': 'application/json',
        };

        if (token !== '') {
            headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: `Bearer ${token}`,
            };
        }

        const config = {
            method,
            url: action,
            headers,
            data: body,
        };

        const response = await axiosInstance.request(config);

        if (response.status === 401) {
            DeleteUser();
        }

        const data = await response.data;
        data.status = response.status;
        return data;
    } catch (error) {
        console.error('error: ', error);
        throw error;
    }
};

export const API_GET = async (action, token) => {
    try {
        let headers = {
            'Content-Type': 'application/json',
        };
        if (token !== '') {
            headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: `Bearer ${token}`,
            };
        }

        const config = {
            method: 'GET',
            url: action,
            headers,
        };

        const response = await axiosInstance.request(config);

        if (response.status === 401) {
            DeleteUser();
        }
        const data = await response.data;
        data.status = response.status;
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const API_DELETE = async (action, token) => {
    try {
        let headers = {
            'Content-Type': 'application/json',
        };
        if (token !== '') {
            headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: `Bearer ${token}`,
            };
        }

        const config = {
            method: 'DELETE',
            url: action,
            headers,
        };

        const response = await axiosInstance.request(config);
        if (response.status === 401) {
            DeleteUser();
        }
        if (response.status === 204) {
            return { status: 200, message: 'User deleted successfully.' };
        }
        if (response.status === 404 || response.status === 400) {
            const data = await response.data;
            data.status = response.status;
            return data;
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export default axiosInstance;
